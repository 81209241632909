import React from 'react'

import './InfoSection.css'
const InfoSection = () => {
    return (
        <div className='info-wrapper'>
            <h1 className='animate-charcter'>Безплатна доставка!</h1>
        </div>
    )
}

export default InfoSection